<template>
  <section class="footer">
    <div class="footerMain">
      <div class="left">
        <h1 class="iconfont icondiceng_logo"></h1>
        <p>{{ $t("PC.components.FooterC.zuk00g") }}</p>
      </div>
      <div class="center">
        <figure class="nav_list">
          <figcaption>{{ $t("PC.components.FooterC.zuk00g") }}</figcaption>
          <ul>
            <li
              v-for="value of navList"
              :key="value.index"
              @mouseup="toPath(value.path)"
            >
              {{ value.name }}
            </li>
          </ul>
        </figure>
        <figure class="introduct">
          <figcaption>{{ $t("PC.components.FooterC.sajv7r") }}</figcaption>
          <ul>
            <li>{{ $t("PC.components.FooterC.r2uzap") }}</li>
            <a href="https://www.sinoaus.net">https://www.sinoaus.net</a>
            <li>
              <a
                href="https://map.baidu.com/search/%E5%8D%97%E4%BA%AC%E5%B8%82%E9%BC%93%E6%A5%BC%E5%8C%BA%E5%8F%A4%E5%B9%B3%E5%B2%974%E5%8F%B7%E6%99%BA%E6%A2%A6%E5%9B%ADb%E5%BA%A75%E6%A5%BC/@13220466.035,3750660.43,19z?querytype=s&da_src=shareurl&wd=%E5%8D%97%E4%BA%AC%E5%B8%82%E9%BC%93%E6%A5%BC%E5%8C%BA%E5%8F%A4%E5%B9%B3%E5%B2%974%E5%8F%B7%E6%99%BA%E6%A2%A6%E5%9B%ADb%E5%BA%A75%E6%A5%BC&c=1&src=0&pn=0&sug=0&l=5&b=(2853289.960466571,1077844.7497895127;18581929.96046657,8753748.749789514)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&device_ratio=1"
              >
                <span class="iconfont icondiceng_dizhi"></span>
              </a>
              <span
                class="iconfont icondiceng_youxiang"
                @click="window.open('mailto:sinoaus@schinper.com')"
              ></span>
            </li>
          </ul>
        </figure>
      </div>
      <div class="right">
        <p style="margin-left: -60px">
          {{ $t("PC.components.FooterC.0e2d4p") }}
        </p>
        <div class="vx">
          <div class="wx1">
            <img src="../../../assets/zh/images/diceng_weixin.jpg" alt="" />
            <p>{{ $t("PC.components.FooterC.0e2d15") }}</p>
          </div>

          <div class="wx2">
            <img
              src="../../../assets/zh/images/diceng_coovally@2x.png"
              alt=""
            />
            <p>{{ $t("PC.components.FooterC.0e2dnb") }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <span>©2020 - 2023 {{ $t("PC.components.FooterC.i2fd8r") }}</span>
      <span>All Rights Reserved</span>
      <span>Designed By Schinper.</span>
      <img src="../../../assets/images/beian.png" alt="">
      <a href="https://beian.mps.gov.cn/#/query/webSearch?code=32010602011358\" rel="\noreferrer\" target="_blank\">{{
        $t("PC.components.FooterC.ffnn5r")
      }}</a>
    </div>
  </section>
</template>

<script>
import window from "./../mixins/window";
export default {
  mixins: [window],
  data() {
    return {
      navList: [
        {
          index: 0,
          name: this.$t("PC.components.FooterC.ljcr45"),
          path: "Home",
        },
        {
          index: 1,
          name: this.$t("PC.components.FooterC.yl2det"),
          path: "Product",
        },
        {
          index: 2,
          name: this.$t("PC.components.FooterC.av935f"),
          path: "Solution",
        },
        {
          index: 3,
          name: this.$t("PC.components.FooterC.hyyc12"),
          path: "Agendas",
        },
        {
          index: 4,
          name: this.$t("PC.components.FooterC.yq5vzh"),
          path: "Service",
        },
        {
          index: 5,
          name: this.$t("PC.components.FooterC.3yoxbi"),
          path: "News",
        },
        {
          index: 6,
          name: this.$t("PC.components.FooterC.z4k0gm"),
          path: "About",
        },
      ],
    };
  },
  methods: {
    toPath(path) {
      this.$router.push({
        path,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 342px;
  text-align: center;
  color: #fff;
  word-break: break-word !important;

  .footerMain {
    height: 276px;
    background-color: #1a1a1a;
    display: flex;
    justify-content: center;
    padding: 41px 0;
    box-sizing: border-box;

    .left {
      padding-right: 58px;
      color: rgba($color: #ffffff, $alpha: 0.7);

      h1.iconfont {
        font-size: 44px;
        padding: 54px 0 20px;
      }

      p {
        width: 144px;
        height: 22px;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .right {
      width: 342px;
      padding-left: 58px;
      color: rgba($color: #ffffff, $alpha: 0.7);
      // background-color: red;
      img {
        width: 124px;
        height: 124px;
      }
      p {
        padding: 14px 0 12px;
        width: 140px;
      }
      .vx {
        display: flex;
        margin-left: -23px;
        .wx1 {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 130px;
        }
        .wx2 {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 130px;
          margin-left: 30px;
        }
      }
    }

    .center {
      width: 589px;
      height: 194px;
      border-left: 2px solid rgba($color: #d8d8d8, $alpha: 0.5);
      border-right: 2px solid rgba($color: #d8d8d8, $alpha: 0.5);
      display: flex;
      justify-content: space-evenly;

      & > figure {
        text-align: left;

        figcaption {
          font-size: 16px;
          line-height: 22px;
          padding: 0px 0 6px;
          color: rgba($color: #ffffff, $alpha: 0.8);
        }

        li {
          display: block;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba($color: #ffffff, $alpha: 0.6);
          line-height: 17px;
          padding: 6px 0;
        }
      }

      .nav_list {
        li {
          padding:3px 0;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      .introduct {
        max-width: 415px;

        figcaption {
          padding-bottom: 20px;
        }

        li,
        a {
          font-size: 16px;
          line-height: 22px;

          span.iconfont {
            color: rgba($color: #ffffff, $alpha: 0.8);
            display: inline-block;
            font-size: 32px;
            margin: 24px 12px 0 0;

            &:hover {
              cursor: pointer;
            }
          }
        }

        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .copyright {
    // white-space: pre;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #252525;
    height: 66px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba($color: #ffffff, $alpha: 0.7);
    line-height: 66px;

    span {
      padding: 0 6px;
    }
    img{
      width: 16px;
      margin-right: 2px;
    }


  }
}
</style>
