export default {
   data() {
      return {
         window: window,
      }
   },
   created() {
   },
   mounted() { },
   methods: {}
}