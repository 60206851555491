<template>
  <div class="applica">
    <div class="appMain">
      <div class="topBack">
        <span
          class="iconfont icon-dingbulan_return"
          style="color: #252631"
          @click="Back()"
          >{{ $t("PC.Product.AISHOPScene.ca5a01") }}</span
        >
      </div>
      <div class="appContent">
        <div class="title">
          <img :src="imgServeUrl + details.url" alt="" />
          <div class="titleContent">
            <p>{{ details.name }}</p>
            <p>
              <span
                v-for="(item, index) in details.labels.split(',')"
                :key="index"
                >{{ item }}</span
              >
            </p>
            <p>{{ $t("PC.Product.AISHOPScene.f49c3f") }}</p>
            <p>
              {{ $t("PC.Product.AISHOPScene.ba8dc1")
              }}{{ details.categories.replace(/,/g, "、") }}
            </p>
          </div>
        </div>
        <div class="detaolsTitle">
          <span class="colorBlock"></span>
          <span>{{ $t("PC.Product.AISHOPScene.163df0") }}</span>
        </div>
        <p class="introduct">
          {{ details.brief }}
        </p>
        <p class="recommend">
          {{ $t("PC.Product.AISHOPScene.09b77d") }}
          <span class="deg">
            <span class="iconfont iconstar" v-for="index of deg" :key="index">
            </span>
            <span class="degA">
              <span
                class="iconfont iconstar"
                v-for="index of details.star"
                :key="index"
                style="color: #f8b929"
              ></span>
            </span>
          </span>
        </p>

        <div class="detaolsTitle">
          <span class="colorBlock"></span>
          <span>{{ $t("PC.Product.AISHOPScene.b59704") }}</span>
        </div>
        <!-- 应用场景 -->
        <div class="face-test">
          <div class="box">
            <p class="img-type tipsTitle">
              {{ $t("PC.Product.AISHOPScene.80d4c8") }}
            </p>
            <div class="imgContain">
              <canvas id="origin" ref="origin" v-show="false">{{
                $t("PC.Product.AISHOPScene.nocava")
              }}</canvas>
              <canvas id="slice" ref="slice" v-show="false">{{
                $t("PC.Product.AISHOPScene.nocava")
              }}</canvas>
              <canvas id="picDraw" ref="picDraw" v-show="false">{{
                $t("PC.Product.AISHOPScene.nocava")
              }}</canvas>
              <div class="imgContainInner">
                <div class="imgContainInnerScroll">
                  <img :src="originImgURL" alt="" />
                </div>
              </div>
              <div class="displayWindow">
                <span
                  @click="swiperPrevB(110)"
                  class="buttons iconfont icon-back"
                ></span>
                <div class="frame-mask">
                  <div
                    class="displayWindowContain"
                    ref="displayWindow"
                    :style="swiperStyleB"
                  >
                    <span
                      v-for="(value, index) in initialPicList"
                      :key="index"
                      @click="
                        picSlecteAble
                          ? slectePicB(index, value.picId, $event)
                          : notMovement
                      "
                    >
                      <div class="options-mask"></div>
                      <img :src="value.picUrl" alt="" />
                    </span>
                  </div>
                </div>
                <span
                  class="buttons iconfont icon-back"
                  style="transform: rotateY(180deg)"
                  @click="swiperNextB(110)"
                >
                </span>
              </div>
            </div>
          </div>
          <div class="box">
            <p class="img-type tipsTitle">
              {{ $t("PC.Product.AISHOPScene.7b5041") }}
            </p>
            <div class="imgContain">
              <div class="imgContainInner" v-loading="loadingVisible">
                <span class="fail-tips">{{ failTips }}</span>
                <div
                  class="imgContainInnerScroll"
                  @click="previewerAblity ? onPreview() : notMovement()"
                >
                  <div class="frame" ref="frame" v-show="frameVisible">
                    <em>
                      <i
                        v-for="(value, index) in frameShowData"
                        :key="index"
                        :style="
                          'left:' +
                          value[0] +
                          '; top:' +
                          value[1] +
                          '; width:' +
                          value[2] +
                          '; height:' +
                          value[3] +
                          ';'
                        "
                      >
                      </i>
                    </em>
                  </div>
                  <img :src="imgURL" alt="" ref="imgDom" v-show="imgVisible" />
                </div>
              </div>
              <div class="displayWindow">
                <span
                  @click="swiperPrevA(110)"
                  class="buttons iconfont icon-back"
                ></span>
                <div class="frame-mask">
                  <div class="displayWindowContain" :style="swiperStyleA">
                    <span
                      v-for="(value, index) in slicesList"
                      :key="index"
                      @click="slectePicA(index, $event)"
                    >
                      <img :src="value" alt="" />
                    </span>
                  </div>
                </div>
                <span
                  class="buttons iconfont icon-back"
                  style="transform: rotateY(180deg)"
                  @click="swiperNextA(110)"
                >
                </span>
              </div>
              <image-view-reform
                v-if="viewerVisible"
                :on-close="closeViewer"
                :url-list="previewPicList"
              >
                <em>
                  <i
                    v-for="(value, index) in frameShowData"
                    :key="index"
                    :style="
                      'left:' +
                      value[0] +
                      '; top:' +
                      value[1] +
                      '; width:' +
                      value[2] +
                      '; height:' +
                      value[3] +
                      ';'
                    "
                  >
                  </i>
                </em>
              </image-view-reform>
            </div>
          </div>
        </div>

        <div class="detaolsTitle">
          <span class="colorBlock"></span>
          <span>{{ $t("PC.Product.AISHOPScene.326e56") }}</span>
        </div>

        <p
          v-for="(value, index) in sceneApplicatList"
          :key="index"
          class="scene"
        >
          <span v-show="$t('locale') == 'zh'">{{ value.name }}：</span>
          <span v-show="$t('locale') == 'zh'">{{ value.description }}</span>
          <span v-show="$t('locale') == 'en'">{{ value.name_en }}：</span>
          <span v-show="$t('locale') == 'en'">{{ value.description_en }}</span>
        </p>
      </div>
    </div>
    <FooterC></FooterC>
  </div>
</template>

<script>
import FooterC from "../../components/FooterC";
export default {
  components: {
    FooterC,
  },
  data() {
    return {
      deg: 5,
      sceneApplicatList: [],
      imgServeUrl: Global.IMG_SERVE,
      loadingVisible: false,
      picSlecteAble: true,
      modelVisible: false,
      previewerAblity: true,
      showPic: "", //橱窗图片
      imgVisible: true, //橱窗图片第一张
      imgURL: "", //服务器返回图片路径
      originImgURL: "", //原始图路径{本地}
      frameShowData: null,
      frameVisible: true, //元素隐藏{@frame}
      previewPicList: [], //预览图列表
      initialPicList: [], //场景初始图片
      slicesList: [], //切图列表
      slicesRelevantList: [], //切图对应列表
      viewerVisible: false, // 显示查看器
      failTips: "", //错误提示
      translateXInitValueA: 0, //切图橱窗偏移值
      translateXInitValueB: 0, //切图橱窗偏移值
      swiperStyleA: `transform: translate3d(${this.translateXInitValueA}px, 0px, 0px);`,
      swiperStyleB: `transform: translate3d(${this.translateXInitValueB}px, 0px, 0px);`,
    };
  },
  created() {
    this.getDetail();
    this.getsceneapplication();
    this.getinitialPicList();
  },
  methods: {
    getDetail() {
      let detail = JSON.parse(sessionStorage.getItem("details"));
      this.scene_id = detail.id;
      if (this.$t("locale") == "zh") {
        this.details = {
          name: detail.scene_name,
          labels: detail.labels,
          categories: detail.categories,
          brief: detail.brief_introduction,
          url: detail.url,
          star: detail.recommend_index,
        };
      } else {
        this.details = {
          name: detail.scene_name_en,
          labels: detail.labels_en,
          categories: detail.categories_en,
          brief: detail.brief_introduction_en,
          url: detail.url,
          star: detail.recommend_index,
        };
      }
    },
    Back() {
      this.$router.push({
        path: "/AIShopNew",
      });
    },

    getsceneapplication() {
      this.axios
        .get(
          Global.COOVALLY_URL +
            Global.GET_SCENE_APPLICAT_LIST +
            "?scene_id=" +
            `${this.scene_id}`
        )
        .then((res) => {
          // console.log(res);
          if (res.status == 200 || res.status == 201) {
            this.sceneApplicatList = res.data.results;
          } else {
            this.$message({
              message: "请求出错",
              type: "error",
            });
          }
        })
        .catch((error) => {
          return error;
        });
    },

    getinitialPicList() {
      this.axios
        .get(
          Global.COOVALLY_URL +
            Global.SCENE_IMG_URL_LIST +
            "?scene_id=" +
            `${this.scene_id}` +
            "&url_typr=2&url_preview_type=0"
        )
        .then((res) => {
          // console.log(res);
          if (res.status == 200 || res.status == 201) {
            for (let _value of res.data.results) {
              let _obj = {};
              _obj.picId = _value.id;
              _obj.picUrl = this.imgServeUrl + _value.url;

              this.initialPicList.push(_obj);
            }
            // console.log(this.initialPicList);
            this.originImgURL = this.initialPicList[0].picUrl;
            this.getsceneInitialPicResult(this.initialPicList[0].picId);
          } else {
            this.$message({
              message: "请求出错",
              type: "error",
            });
          }
        })
        .catch((error) => {
          return error;
        });
    },

    getsceneInitialPicResult(picId) {
      this.axios
        .get(
          Global.COOVALLY_URL +
            Global.SCENE_PRIVIEW_URL +
            "?original_id=" +
            `${picId}`
        )
        .then((res) => {
          console.log(res);
          if (res.status == 200 || res.status == 201) {
            this.slicesList = [];
            this.slicesRelevantList = [];
            this.previewPicList = [];
            let _res = res.data.results;
            console.log(_res);
            _res.forEach((value, index) => {
              if (index % 2 == 1) {
                this.slicesRelevantList.push(this.imgServeUrl + value.url);
              } else {
                this.slicesList.push(this.imgServeUrl + value.url);
              }
            });
            this.imgURL = this.imgServeUrl + _res[1].url;
            this.previewPicList.push(this.imgServeUrl + _res[1].url);

            this.loadingVisible = false;
          } else {
            this.$message({
              message: "请求出错",
              type: "error",
            });
          }
        })
        .catch((error) => {
          return error;
        });
    },
    onPreview() {
      if (!this.imgURL) return;
      this.viewerVisible = true;
    },
    closeViewer() {
      this.viewerVisible = false;
    },

    swiperPrevA(value) {
      if (this.translateXInitValueA > -110) {
        return;
      }
      this.translateXInitValueA = this.translateXInitValueA + value;
      this.swiperStyleA = `transform: translate3d(${this.translateXInitValueA}px, 0px, 0px);`;
    },
    swiperNextA(value) {
      if (this.slicesList.length * 110 + this.translateXInitValueA <= 400) {
        return;
      }
      this.translateXInitValueA = this.translateXInitValueA - value;
      this.swiperStyleA = `transform: translate3d(${this.translateXInitValueA}px, 0px, 0px);`;
    },
    slectePicA(index, e) {
      if (index == 0) {
        this.frameVisible = true;
        this.previewerAblity = true;
      } else {
        this.frameVisible = false;
        this.previewerAblity = false;
      }
      if (this.modelVisible) {
        if (index == 0) {
          this.imgURL = this.originImgURL;
          this.frameVisible = true;
        } else {
          this.imgURL = this.faceMarkSlicesList[index - 1];
          this.frameVisible = false;
        }
      } else {
        this.imgURL = this.slicesRelevantList[index];
        this.frameShowData = null;
      }
      for (let value of e.target.parentNode.parentNode.children) {
        value.style.borderColor = "#999";
        value.style.borderWidth = "1px";
      }
      e.target.parentNode.style.borderColor = "#396dd6";
      e.target.parentNode.style.borderWidth = "2px";
    },
    swiperPrevB(value) {
      if (this.translateXInitValueB + value <= 0) {
        this.translateXInitValueB = this.translateXInitValueB + value;
        this.swiperStyleB = `transform: translate3d(${this.translateXInitValueB}px, 0px, 0px);`;
      }
    },
    swiperNextB(value) {
      if (
        -(this.initialPicList.length * 120 - 620) -
          this.translateXInitValueB -
          value <=
        120
      ) {
        this.translateXInitValueB = this.translateXInitValueB - value;
        this.swiperStyleB = `transform: translate3d(${this.translateXInitValueB}px, 0px, 0px);`;
      }
    },
    slectePicB(index, picId, e) {
      this.loadingVisible = true;
      this.slicesList = [];
      this.imgURL = null;
      this.imgVisible = true;
      this.frameVisible = false;
      this.frameShowData = null;
      this.originImgURL = this.initialPicList[index].picUrl;
      this.swiperStyleA = `transform: translate3d(0px, 0px, 0px);`;
      this.translateXInitValueA = 0;
      for (let value of e.target.parentNode.parentNode.children) {
        value.style.borderColor = "#999";
        value.style.borderWidth = "1px";
      }
      e.target.parentNode.style.borderColor = "#396dd6";
      e.target.parentNode.style.borderWidth = "2px";
      this.getsceneInitialPicResult(picId);
    },
    notMovement() {},
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-main {
  width: 100%;
  padding: 0;
  height: 100%;
  overflow-y: scroll; //y轴滚动条始终存在，防止画面抖动
  overflow-x: hidden;
}
.appMain {
  width: 960px;
  margin: 0 auto 90px;
  padding-top: 40px;
  .topBack {
    cursor: pointer;
    margin: 32px 0 12px;

    span {
      font-size: 14px;
      color: #396dd6;
      box-sizing: border-box;
      padding: 10px;
      padding-left: 0;

      &::before {
        margin-right: 4px;
        display: inline-block;
        font-size: 10px;
        transform: scale(0.8);
        transform-origin: center center;
      }
    }
  }

  .appContent {
    height: 100%;
    font-size: 14px;
    color: #666666;

    .introduct {
      line-height: 22px;
    }

    .scene {
      display: flex;
      text-align: left;
      margin-bottom: 10px;

      span {
        display: inline-block;
      }

      span:nth-of-type(1) {
        color: #333;
        white-space: nowrap;
      }

      span:nth-of-type(2) {
        color: #666;
      }
    }

    .recommend {
      color: #333333;
      margin-top: 20px;

      .deg {
        position: relative;

        .iconstar {
          font-size: 12px;
          margin-right: 6px;
          color: #eff2f6;
        }

        .degA {
          position: absolute;
          left: 0;
          z-index: 2;

          .iconstar-active {
            font-size: 12px;
            margin-right: 6px;
            margin-right: 6px;
            color: #f8b929;
          }
        }
      }
    }

    .title {
      display: flex;

      img {
        height: 180px;
      }

      .titleContent {
        margin-left: 30px;

        p {
          color: #666666;
          margin: 20px 0;
        }

        p:nth-of-type(1) {
          margin: 15px 0 14px;
          font-size: 18px;
          color: #333333;
        }

        p:nth-of-type(2) {
          margin: 0;

          span {
            margin-right: 12px;
            display: inline-block;
            padding: 8px 11px;
            background: #f5fcfe;
            border-radius: 4px;
            color: #4aa3ff;
            border: 1px solid #e8f7fb;
          }
        }

        p:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .detaolsTitle {
      display: flex;
      margin: 40px 0 10px;

      span {
        font-size: 18px;
        font-weight: 600;
        color: #333333;
      }

      .colorBlock {
        margin-right: 10px;
        align-self: center;
        width: 4px;
        height: 18px;
        background: #396dd6;
      }
    }
  }
  .face-test {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .box {
      width: 470px;
      display: flex;
      flex-direction: column;
      justify-content: start;

      p.tipsTitle {
        font-size: 12px;
        font-weight: 400;
        color: #aaaaaa;
        line-height: 17px;
      }

      .optionBox {
        display: flex;
        justify-content: start;
        align-items: center;

        // 改写input默认样式
        .a-upload {
          padding: 4px 10px;
          margin: 10px 0;
          width: 86px;
          height: 30px;
          line-height: 20px;
          position: relative;
          cursor: pointer;
          color: #fff;
          background-color: #396dd6;
          font-size: 12px;
          border-radius: 4px;
          overflow: hidden;
          display: inline-block;

          span {
            background-color: transparent;
            width: 50px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          input {
            padding: 30px;
            z-index: 1;
            width: 100%;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            background-color: transparent;
            opacity: 0;
            filter: alpha(opacity=0);
            cursor: pointer !important;
          }

          &:hover {
            background-color: rgba(57, 109, 214, 0.8);
            text-decoration: none;
          }
        }

        .el-select {
          width: 150px;
          margin-left: 10px;

          ::v-deep input {
            height: 32px;
          }

          ::v-deep .el-input__icon {
            line-height: 32px;
          }
        }
      }

      .imgContain {
        width: 470px;
        background-color: #f5f5f5;
        padding: 10px;

        em {
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: transparent;

          i {
            display: block;
            background-color: transparent;
            border: 1px solid #00bfff;
            position: absolute;
            z-index: 1;
          }
        }

        .el-image-viewer__img-bro {
          em {
            i {
              border: 2px solid #00bfff;
            }
          }
        }

        .displayWindow {
          margin-top: 10px;
          width: 100%;
          height: 105px;
          display: flex;
          justify-content: space-between;

          span.buttons {
            width: 30px;
            display: inline-block;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(192, 192, 192, 0.9);

            &:hover {
              cursor: pointer;
              background-color: rgba(192, 192, 192, 0.8);
            }
          }

          span.buttons:nth-of-type(1) {
            margin-right: 5px;
          }

          span.buttons:nth-of-type(2) {
            margin-left: 5px;
          }

          div.frame-mask {
            flex: 1;
            overflow: hidden;

            .displayWindowContain {
              transition-duration: 500ms;
              height: 100%;
              position: relative;
              white-space: nowrap;

              .options-mask {
                height: 100%;
                position: relative;
                z-index: 9;
                background-color: transparent;
              }

              span {
                margin: 0 5px;
                display: inline-block;
                width: 100px;
                height: 100px;
                background-color: #fff;
                border: 1px solid #999;
                position: relative;

                img {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  max-width: 100%;
                  max-height: 100%;
                  vertical-align: middle;
                }
              }

              span:nth-of-type(1) {
                border: 2px solid #396dd6;
              }
            }
          }
        }

        .imgContainInner {
          width: 100%;
          height: 349px;
          position: relative;
          overflow: hidden;
          text-align: center;

          .fail-tips {
            white-space: nowrap;
            position: absolute;
            left: 50%;
            top: 30%;
            transform: translate(-50%, -50%);
          }

          .imgContainInnerScroll {
            position: relative;
            height: 100%;
            width: 100%;

            img {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              max-width: 100%;
              max-height: 100%;
              vertical-align: middle;
              z-index: 0;
            }

            .frame {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              max-width: 100%;
              max-height: 100%;
              vertical-align: middle;
              background-color: transparent;
              z-index: 99;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .footer {
    flex: 0;
  }
}
</style>
